<template>
  <b-container>
    <b-row>
      <add-company @create="create" />
    </b-row>
  </b-container>
</template>

<script>
import AddCompany from '@/components/AddCompany.vue'
import { mapActions, mapGetters } from 'vuex'
import axiosClient from '../../http'
import router from '@/routes'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'NewCompanySelection',
  components: { AddCompany },
  data: function() {
    return {
      companyCreated: null,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'count',
    ]),
  },
  methods: {
    ...mapActions('checkout', [
      'loadCartItems',
    ]),
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    create: async function(companyName, entityTypeId) {
      const record = { name: companyName, entity_type_id: entityTypeId }

      const response = await axiosClient.post('client/companies', {
        company: record,
      })

      if (!this.count) {
        createOrFindClientInteractionLog({
          category: 'page-interaction',
          subCategory: 'stageline-v2-landing-page',
          incompleteLogFromToday: true,
          interaction: {
            action: 'completed',
            name: 'stageline-zero-companies',
            location: 'hire-us',
          },
          completeLog: true,
        })
      }

      const companyId = response.data.result.id

      const context = { companyId: companyId }

      await this.$store.dispatch('checkout/setupContext', context)
      await this.$store.dispatch('session/setInitialized', { storeName: 'companies', value: false })

      await this.setCurrentCompany({ id: companyId, force: true })

      await this.loadCartItems()

      await router.push({ name: 'choose-category', params: { companyId } })
    },
  },
}
</script>
